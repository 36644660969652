import React, { useContext } from "react";

import { Link, useNavigate } from "react-router-dom";

import SelectInput from '../../../components/SelectInput/SelectInput';
import AdminContext from '../../../contexts/AdminContext';

import './styles.css';

import logout from '../../../assets/icons/logout-white.png';

import home from '../../../assets/admin/home.svg';
import clover from '../../../assets/admin/clover.svg';
import key from '../../../assets/admin/key.svg';
import result from '../../../assets/admin/result.svg';
import trophy from '../../../assets/admin/trophy.svg';
import user from '../../../assets/admin/user.svg';
import voucher from '../../../assets/admin/voucher.svg';
import activity from '../../../assets/admin/activity.svg';
import tracking from '../../../assets/admin/tracking.svg';


function SideBarAdmin() {
    const navigate = useNavigate();
    const links = [
        {
            label: 'Home',
            path: 'home',
            icon: home
        },

        {
            label: 'Sorteios',
            path: 'sorteios',
            icon: clover
        },

        {
            label: 'Números da Sorte',
            path: 'numeros',
            icon: voucher
        },

        {
            label: 'Ganhadores',
            path: 'ganhadores',
            icon: trophy
        },

        {
            label: 'Rastreamento',
            path: 'rastreamento',
            icon: tracking
        },

        {
            label: 'Exportar cadastros',
            path: 'cadastrados',
            icon: user
        },

        {
            label: 'Resultados',
            path: 'resultados',
            icon: result
        },

        {
            label: 'Atividade',
            path: 'atividade',
            icon: activity
        },

        {
            label: 'Adicionar Admin',
            path: 'adicionar',
            icon: key
        },
    ];

    const { promos, currentPromo, setCurrentPromo } = useContext(AdminContext);

    const handleLogout = () => {
        localStorage.removeItem('adminAccessToken');
        localStorage.removeItem('role');
        navigate('/admin');
    }

    return (
        <div className="admin-sidebar-container">
            <p className="admin-sidebar-dropdown-title">Promoção</p>
            <div className="admin-sidebar-dropdown-container">
                <SelectInput
                    options={promos}
                    value={currentPromo}
                    setOption={(value) => setCurrentPromo(value)}
                    rubik
                />
            </div>
            <div className="admin-sidebar-line"></div>
            <div className="admin-sidebar-links-container">
                {
                    links.map((link) => (
                        <Link
                            key={link.label}
                            className={`admin-sidebar-link-container ${window.location.pathname.includes(link.path) ? 'selected-link-container' : ''}`}
                            to={`/admin/${link.path}`}
                        >
                            <img src={link.icon} alt={`${link.path}-icon`} className="admin-sidebar-link-icon" />
                            <p className={`admin-sidebar-link-text ${window.location.pathname.includes(link.path) ? 'selected-link-text' : ''}`}>{link.label}</p>
                        </Link>
                    ))
                }
            </div>
            <div className="admin-sidebar-logout-container" onClick={handleLogout}>
                <img src={logout} alt="logout-icon" className="admin-sidebar-link-icon" />
                <p className="admin-sidebar-link-text">Sair</p>
            </div>
        </div>
    )
}

export default SideBarAdmin;