import React, { useContext, useState } from "react";

import './styles.css';

import FileInput from "../../../components/Admin/FileInput/FileInput";
import DownloadModel from "../../../components/Admin/DownloadModel/DownloadModel";

import tryImportingTracking from "./imports/tracking";

import useAuthAdmin from "../../../hooks/admin/useAuthAdmin";
import useSetPageTitle from "../../../hooks/useSetPageTitle";

import DefaultLoader from "../../../components/Loader/DefaultLoader";
import AdminContext from "../../../contexts/AdminContext";

function TrackingAdmin() {
    const { currentPromo } = useContext(AdminContext);

    useAuthAdmin();
    useSetPageTitle(`${currentPromo?.label ?? 'Promo Solar'} | Admin`);
    
    const [file, setFile] = useState(null);
    const [loadingImport, setLoadingImport] = useState(false);
    const [errorImport, setErrorImport] = useState(null);
    const [successImport, setSuccessImport] = useState(null);

    const downloadModel = () => {
        const link = document.createElement('a');
        link.href = "/models/modelo-rastreamento.csv";
        link.download = "modelo-rastreamento.csv";
        link.click();
    };


    const uploadResults = () => {
        setLoadingImport(true);
        tryImportingTracking(file, (success, error) => {
            setSuccessImport(success ? 'Sua base foi importada com sucesso.' : null);
            setErrorImport(error ?? null);
            setLoadingImport(false);
        })
    }

    return(
        <div className="admin-page-container">
            <div className="admin-page-title-container">
                <p className="admin-page-title">Importar códigos de rastreamento</p>
                {
                    1 == 2 ?
                    <DownloadModel label="Baixe o modelo de planilha" onClick={downloadModel} />
                    : false
                }
            </div>
            <div style={{ marginBottom: '20px' }} />
            <FileInput setValue={setFile} />
            <div style={{ marginBottom: '20px' }} />
            <div className="admin-page-button-container">
                <button className="admin-page-primary-button" onClick={() => uploadResults()}>Enviar</button><br />
                { loadingImport ? <DefaultLoader /> : false }
            </div>
            <p className="admin-page-error-import">{errorImport}</p>
            <p className="admin-page-success-import">{successImport}</p>
        </div>
    )
}

export default TrackingAdmin;