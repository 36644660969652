import React from 'react';
import axios from 'axios';

import './styles.css';

function ResultsTable({ results }) {

    const handleEdit = (e, id) => {
        axios
            .put(`${process.env.REACT_APP_API_URL}/admin/resultados/${id}`, {
                status: e.target.value
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
                }
            })
            .then((response) => {
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const possibleStatus = ['analise_endereco', 'envio_pendente', 'enviado']
    return(
        <div>
            <table className="log-table">
                <thead>
                    <tr>
                        <th style={{ opacity: '0.3' }}>#</th>
                        <th>Razão Social</th>
                        <th>Número da sorte</th>
                        <th>Prêmio</th>
                        <th>Rastreio</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        results.map((row, idx) => (
                            <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>{row.client.nome}</td>
                                <td>{row.voucher}</td>
                                <td>{row.premio.nome}</td>
                                <td>{row.rastreio}</td>
                                <td>
                                    <select onChange={(e) => handleEdit(e, row.id)}>
                                        <option>{row.status}</option>
                                        {
                                            possibleStatus
                                            .filter(status => row.status !== status)
                                            .map(option => (
                                                <option key={option}>{option}</option>
                                            ))
                                        }
                                    </select>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default ResultsTable;