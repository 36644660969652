import axios from 'axios';

    
const tryImportingTracking = (file, callback) => {
    if (!file) {
        return callback(false, 'Arquivo obrigatório.')
    }
    const formData = new FormData();
    formData.append('file', file);
    axios
        .post(`${process.env.REACT_APP_API_URL}/admin/rastreio`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
            },
        })
        .then((response) => {
            callback(true)
        })
        .catch((err) => {
            callback(false, err.response.data.message)
        })
}

export default tryImportingTracking;