import React, { useContext, useEffect, useState } from "react";

import './styles.css';
import '../../components/Forms/AddressForm/styles.css'

import Status from "../../components/Status/Status";

import useAuthClient from "../../hooks/useAuthClient";
import useFetchClientsResults from "../../hooks/useFetchClientsResults";
import useSetPageTitle from "../../hooks/useSetPageTitle";
import UserContext from "../../contexts/UserContext";
import ClipboardJS from "clipboard";



function MyPrizes() {

    useEffect(() => {
        const clipboard = new ClipboardJS('.tracking');
        clipboard.on('success', function (e) {
            console.info('Action:', e.action);
            console.info('Text:', e.text);
            console.info('Trigger:', e.trigger);
            e.trigger.classList.add('copied');
            setTimeout(() => e.trigger.classList.remove('copied'), 500);
            e.clearSelection();
        });

        clipboard.on('error', function (e) {
            console.error('Action:', e.action);
            console.error('Trigger:', e.trigger);
        });

    }, [])

    const { currentPromo } = useContext(UserContext);
    const [showHint, setShowHint] = useState(true);

    useAuthClient();
    useSetPageTitle(`${currentPromo?.label} | Prêmios`);

    const { results } = useFetchClientsResults();

    return (
        <div className="prizes-background page-container">
            <h1 className="title text-yellow">Meus prêmios</h1>
            <div className="prizes-table-container">
                <div className="card-list">
                    {
                        results
                            .map((item, index) => (
                                <div className="card" key={index}>
                                    <span>Número da sorte: <strong>#{item.voucher}</strong></span>
                                    <h2><strong className="title text-white">{item.premio.nome}</strong></h2>
                                    <div className="card-content">
                                        <Status status={item.status} />
                                    </div>
                                    {item.rastreio &&
                                        <span>
                                            Código de Rastreio:
                                            <br/>
                                            <span id={`tracking-${index}`}
                                                data-clipboard-text={item.rastreio}
                                                data-clipboard-target={`#tracking-${index}`}
                                                className={`tracking ${index === 0 && showHint ? 'hinted' : ''}`}
                                                onClick={() => setShowHint(false)}>
                                                <bdi>{item.rastreio}</bdi>
                                                <small className="tooltip">Copiado</small>
                                                <small className="hint">Clique para copiar</small>
                                            </span>
                                        </span>}
                                </div>
                            ))
                    }
                    {!results.length && <p className="prizes-to-withdraw-subtitle title text-white">Você ainda não ganhou<br /> <br />Mas continue comprando, quanto mais números da sorte, mais chances você tem de ganhar! </p>}

                </div>
            </div>
        </div>
    )
}

export default MyPrizes;